import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";

import { lingua_it } from "../i18n/it-IT";
import { lingua_en } from "../i18n/en-GB";
import { lingua_es } from "../i18n/es-ES";
import { lingua_ca } from "../i18n/es-CA";

const SizesTables = (props) => {
  let lingua = {};

  switch (props.lang) {
    case "it-IT":
      lingua = lingua_it;
      break;
    case "es-CA":
      lingua = lingua_ca;
      break;
    case "es-ES":
      lingua = lingua_es;
      break;
    case "en-GB":
      lingua = lingua_en;
      break;
    default:
      lingua = lingua_it;
  }

  return (
    <div className="section">
      <div className="container">
        <div className="content">
          <h1 className="title is-spaced is-3" style={{ marginBottom: "4rem" }}>
            {lingua.navigazione.misure.tabellaTaglie}
          </h1>

          <h2 className="subtitle is-4">
            {lingua.navigazione.misure.slimDonna}
          </h2>
          <div className="table-container">
            <table className="table" style={{ marginBottom: "4rem" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>{lingua.navigazione.misure.small}</th>
                  <th>{lingua.navigazione.misure.medium}</th>
                  <th>{lingua.navigazione.misure.large}</th>
                  <th>{lingua.navigazione.misure.extralarge}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Torace}
                    </span>
                  </th>
                  <td>94</td>
                  <td>96</td>
                  <td>98</td>
                  <td>100</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Vita}
                    </span>
                  </th>
                  <td>77</td>
                  <td>81</td>
                  <td>85</td>
                  <td>88</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Collo}
                    </span>
                  </th>
                  <td>35</td>
                  <td>36</td>
                  <td>37</td>
                  <td>38</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Spalle}
                    </span>
                  </th>
                  <td>38</td>
                  <td>39</td>
                  <td>40</td>
                  <td>41</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Manica}
                    </span>
                  </th>
                  <td>58</td>
                  <td>60</td>
                  <td>62</td>
                  <td>64</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Lunghezza}
                    </span>
                  </th>
                  <td>65</td>
                  <td>66</td>
                  <td>67</td>
                  <td>68</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 className="subtitle is-4">
            {lingua.navigazione.misure.regularDonna}
          </h2>
          <div className="table-container">
            <table className="table" style={{ marginBottom: "4rem" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>{lingua.navigazione.misure.small}</th>
                  <th>{lingua.navigazione.misure.medium}</th>
                  <th>{lingua.navigazione.misure.large}</th>
                  <th>{lingua.navigazione.misure.extralarge}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Torace}
                    </span>
                  </th>
                  <td>96</td>
                  <td>100</td>
                  <td>104</td>
                  <td>108</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Vita}
                    </span>
                  </th>
                  <td>86</td>
                  <td>88</td>
                  <td>92</td>
                  <td>94</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Collo}
                    </span>
                  </th>
                  <td>35</td>
                  <td>36</td>
                  <td>38</td>
                  <td>39</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Spalle}
                    </span>
                  </th>
                  <td>43</td>
                  <td>44</td>
                  <td>45</td>
                  <td>46</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Manica}
                    </span>
                  </th>
                  <td>59</td>
                  <td>59.5</td>
                  <td>60</td>
                  <td>61</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Lunghezza}
                    </span>
                  </th>
                  <td>69</td>
                  <td>70</td>
                  <td>71</td>
                  <td>72</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 className="subtitle is-4">
            {lingua.navigazione.misure.boyfriendDonna}
          </h2>
          <div className="table-container">
            <table className="table" style={{ marginBottom: "4rem" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>{lingua.navigazione.misure.small}</th>
                  <th>{lingua.navigazione.misure.medium}</th>
                  <th>{lingua.navigazione.misure.large}</th>
                  <th>{lingua.navigazione.misure.extralarge}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Torace}
                    </span>
                  </th>
                  <td>98</td>
                  <td>102</td>
                  <td>106</td>
                  <td>110</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Vita}
                    </span>
                  </th>
                  <td>88</td>
                  <td>90</td>
                  <td>94</td>
                  <td>96</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Collo}
                    </span>
                  </th>
                  <td>36</td>
                  <td>37</td>
                  <td>38</td>
                  <td>39</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Spalle}
                    </span>
                  </th>
                  <td>44</td>
                  <td>44.5</td>
                  <td>45</td>
                  <td>46</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Manica}
                    </span>
                  </th>
                  <td>56.5</td>
                  <td>57</td>
                  <td>58.5</td>
                  <td>60</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Lunghezza}
                    </span>
                  </th>
                  <td>69</td>
                  <td>71</td>
                  <td>72</td>
                  <td>74</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 className="subtitle is-4">
            {lingua.navigazione.misure.poloDonna}
          </h2>
          <div className="table-container">
            <table className="table" style={{ marginBottom: "4rem" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>{lingua.navigazione.misure.small}</th>
                  <th>{lingua.navigazione.misure.medium}</th>
                  <th>{lingua.navigazione.misure.large}</th>
                  <th>{lingua.navigazione.misure.extralarge}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Torace}
                    </span>
                  </th>
                  <td>96</td>
                  <td>100</td>
                  <td>104</td>
                  <td>108</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Vita}
                    </span>
                  </th>
                  <td>88</td>
                  <td>90</td>
                  <td>94</td>
                  <td>96</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Collo}
                    </span>
                  </th>
                  <td>35</td>
                  <td>36</td>
                  <td>38</td>
                  <td>39</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Spalle}
                    </span>
                  </th>
                  <td>43</td>
                  <td>44</td>
                  <td>45</td>
                  <td>46</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Manica}
                    </span>
                  </th>
                  <td>61</td>
                  <td>61.5</td>
                  <td>62</td>
                  <td>62.5</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Lunghezza}
                    </span>
                  </th>
                  <td>69</td>
                  <td>70</td>
                  <td>72</td>
                  <td>73</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 className="subtitle is-4">
            {lingua.navigazione.misure.vestitoslimDonna}
          </h2>
          <div className="table-container">
            <table className="table" style={{ marginBottom: "4rem" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>{lingua.navigazione.misure.small}</th>
                  <th>{lingua.navigazione.misure.medium}</th>
                  <th>{lingua.navigazione.misure.large}</th>
                  <th>{lingua.navigazione.misure.extralarge}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Torace}
                    </span>
                  </th>
                  <td>88</td>
                  <td>89</td>
                  <td>90</td>
                  <td>91</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Vita}
                    </span>
                  </th>
                  <td>78</td>
                  <td>80</td>
                  <td>82</td>
                  <td>84</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Collo}
                    </span>
                  </th>
                  <td>35</td>
                  <td>36</td>
                  <td>37</td>
                  <td>38</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Spalle}
                    </span>
                  </th>
                  <td>38</td>
                  <td>39</td>
                  <td>40</td>
                  <td>41</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Manica}
                    </span>
                  </th>
                  <td>58</td>
                  <td>59</td>
                  <td>60.5</td>
                  <td>61.5</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Lunghezza}
                    </span>
                  </th>
                  <td>91</td>
                  <td>93</td>
                  <td>94</td>
                  <td>95</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 className="subtitle is-4">
            {lingua.navigazione.misure.vestitoregularDonna}
          </h2>
          <div className="table-container">
            <table className="table" style={{ marginBottom: "4rem" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>{lingua.navigazione.misure.small}</th>
                  <th>{lingua.navigazione.misure.medium}</th>
                  <th>{lingua.navigazione.misure.large}</th>
                  <th>{lingua.navigazione.misure.extralarge}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Torace}
                    </span>
                  </th>
                  <td>90</td>
                  <td>91</td>
                  <td>93</td>
                  <td>94</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Vita}
                    </span>
                  </th>
                  <td>80</td>
                  <td>82</td>
                  <td>84</td>
                  <td>86</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Collo}
                    </span>
                  </th>
                  <td>36</td>
                  <td>37</td>
                  <td>38</td>
                  <td>39</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Spalle}
                    </span>
                  </th>
                  <td>43</td>
                  <td>43.5</td>
                  <td>44</td>
                  <td>44.5</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Manica}
                    </span>
                  </th>
                  <td>60</td>
                  <td>61</td>
                  <td>61.5</td>
                  <td>62</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Lunghezza}
                    </span>
                  </th>
                  <td>91</td>
                  <td>93</td>
                  <td>94</td>
                  <td>95</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 className="subtitle is-4">
            {lingua.navigazione.misure.camiciaUomo}
          </h2>
          <div className="table-container">
            <table className="table" style={{ marginBottom: "4rem" }}>
              <tbody>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Collo}
                    </span>
                  </th>
                  <th>37/XS</th>
                  <th>38/S</th>
                  <th>39/M</th>
                  <th>40/M</th>
                  <th>41/L</th>
                  <th>42/L</th>
                  <th>43/XL</th>
                  <th>44/XL</th>
                  <th>45/XXL</th>
                  <th>46/XXL</th>
                  <th>47/XXXL</th>
                  <th>48/XXXL</th>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Torace}
                    </span>
                  </th>
                  <td>88</td>
                  <td>92</td>
                  <td>96</td>
                  <td>100</td>
                  <td>104</td>
                  <td>108</td>
                  <td>114</td>
                  <td>115</td>
                  <td>116</td>
                  <td>118</td>
                  <td>120</td>
                  <td>124</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Vita}
                    </span>
                  </th>
                  <td>86</td>
                  <td>90</td>
                  <td>94</td>
                  <td>98</td>
                  <td>100</td>
                  <td>106</td>
                  <td>110</td>
                  <td>112</td>
                  <td>118</td>
                  <td>120</td>
                  <td>122</td>
                  <td>126</td>
                </tr>

                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Spalle}
                    </span>
                  </th>
                  <td>44</td>
                  <td>45</td>
                  <td>47</td>
                  <td>47</td>
                  <td>49</td>
                  <td>51</td>
                  <td>51.5</td>
                  <td>52</td>
                  <td>52.5</td>
                  <td>53</td>
                  <td>53.5</td>
                  <td>54</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Manica}
                    </span>
                  </th>
                  <td>64</td>
                  <td>64.5</td>
                  <td>65</td>
                  <td>65.5</td>
                  <td>65.5</td>
                  <td>66</td>
                  <td>66</td>
                  <td>66.5</td>
                  <td>67</td>
                  <td>67</td>
                  <td>67.5</td>
                  <td>67.5</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Lunghezza}
                    </span>
                  </th>
                  <td>77</td>
                  <td>77</td>
                  <td>78</td>
                  <td>78</td>
                  <td>79</td>
                  <td>79</td>
                  <td>80</td>
                  <td>80</td>
                  <td>81</td>
                  <td>81</td>
                  <td>82</td>
                  <td>83</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 className="subtitle is-4">
            {lingua.navigazione.misure.camiciaBambino}
          </h2>
          <div className="table-container">
            <table className="table">
              <tbody>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.anni}
                    </span>
                  </th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>5</th>
                  <th>6</th>
                  <th>7</th>
                  <th>8</th>
                  <th>9</th>
                  <th>10</th>
                  <th>11</th>
                  <th>12</th>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.statura}
                    </span>
                  </th>
                  <td>87</td>
                  <td>91</td>
                  <td>95</td>
                  <td>100</td>
                  <td>105</td>
                  <td>110</td>
                  <td>115</td>
                  <td>120</td>
                  <td>125</td>
                  <td>130</td>
                  <td>135</td>
                  <td>140</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Torace}
                    </span>
                  </th>
                  <td>53</td>
                  <td>56</td>
                  <td>59</td>
                  <td>62</td>
                  <td>65</td>
                  <td>68</td>
                  <td>71</td>
                  <td>74</td>
                  <td>77</td>
                  <td>80</td>
                  <td>83</td>
                  <td>86</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Vita}
                    </span>
                  </th>
                  <td>51</td>
                  <td>53</td>
                  <td>55.5</td>
                  <td>57.5</td>
                  <td>59.5</td>
                  <td>61.5</td>
                  <td>64</td>
                  <td>67</td>
                  <td>69</td>
                  <td>71</td>
                  <td>74</td>
                  <td>77</td>
                </tr>

                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Spalle}
                    </span>
                  </th>
                  <td>22.3</td>
                  <td>22.4</td>
                  <td>24.6</td>
                  <td>25.8</td>
                  <td>27</td>
                  <td>28.2</td>
                  <td>29.4</td>
                  <td>30.6</td>
                  <td>31.8</td>
                  <td>33</td>
                  <td>34.2</td>
                  <td>35.4</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Manica}
                    </span>
                  </th>
                  <td>30</td>
                  <td>32</td>
                  <td>33</td>
                  <td>35</td>
                  <td>38</td>
                  <td>41</td>
                  <td>43</td>
                  <td>45</td>
                  <td>47</td>
                  <td>48.5</td>
                  <td>50</td>
                  <td>51.5</td>
                </tr>
                <tr>
                  <th>
                    <span className="is-uppercase">
                      {lingua.navigazione.misure.Lunghezza}
                    </span>
                  </th>
                  <td>36</td>
                  <td>38</td>
                  <td>39</td>
                  <td>41</td>
                  <td>44</td>
                  <td>47</td>
                  <td>49</td>
                  <td>53</td>
                  <td>54</td>
                  <td>56.5</td>
                  <td>58</td>
                  <td>60</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(SizesTables);
